<template>
  <div class="table-container">
    <p class="has-text-right" style="padding:10px;">
      Current Balance: <strong>{{ USD(currentBusiness.balance).format() }}</strong>
    </p>
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-size-7">
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Claim type</th>
          <th>Approved by</th>
          <th>LL Fee</th>
          <th>CC Fee</th>
          <th>Amount</th>
          <th>Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="r in data"
          :key="r.id"
        >
          <td>{{ r.date }}</td>
          <td>{{ r.description }}</td>
          <td>{{ r.category }}</td>
          <td>{{ r.approved_by }}</td>
          <td>{{ USD(r.ll_fee).format() }}</td>
          <td>{{ USD(r.cc_fee).format() }}</td>
          <td>{{ USD(r.amount).format() }}</td>
          <td>{{ USD(r.balance).format() }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  }
};
</script>
