<template>
  <ReportPage>
    <OrganizationTransactionsApproved
      v-if="status == 'approved'"
      :data="transactions"
    />
    <OrganizationTransactionsPending
      v-if="status == 'pending'"
      :data="transactions"
    />
    <OrganizationTransactionsRejected
      v-if="status == 'rejected'"
      :data="transactions"
    />
  </ReportPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import ReportPage from "@/components/ReportPage.vue";
import OrganizationTransactionsApproved from "@/components/table/OrganizationTransactionsApproved.vue";
import OrganizationTransactionsPending from "@/components/table/OrganizationTransactionsPending.vue";
import OrganizationTransactionsRejected from "@/components/table/OrganizationTransactionsRejected.vue";

export default {
  props: {
    status: {
      type: String,
      default: "approved"
    },
  },
  components: {
    ReportPage,
    OrganizationTransactionsApproved,
    OrganizationTransactionsPending,
    OrganizationTransactionsRejected
  },
  data() {
    return {
      loading: false,
      transactions: []
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  },
  methods: {
    loadData(status) {
      this.loading = true;
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        status: status
      }
      this.$store.dispatch("getBusinessTransactions", params)
        .then(resp => {
          this.transactions = resp
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            this.error = "Could not load transactions"
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async mounted() {
    this.loading = true;
    const params = {
      user_slug: this.currentUser.slug,
      org_slug: this.currentBusiness.slug,
      status: this.status
    }
    try {
      this.transactions = await this.$store.dispatch("getBusinessTransactions", params)
    } catch(ex) {
      this.error = "Could not load transactions"
    } finally {
      this.loading = false
    }
  },
}


</script>
