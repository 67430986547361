<template>
  <div class="table-container">
    <p>
      Deals pending approval are listed below. Please reconcile them with your sales and approve them, as appropriate. All approvals are final.
    </p>
    <p>
      <em>Deals that are rejected may result in a poor customer experience if the customer has completed a purchase and is entitled to a reward.</em>
    </p>
    <table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-size-7">
      <thead>
        <tr>
          <th width="60px"></th>
          <th>Date</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="r in data"
          :key="r.id"
        >
          <td>
            <button
              @click="approve($event, r.id)"
              class="button is-small is-primary"
            >
              approve
            </button>
          </td>
          <td>{{ r.date }}</td>
          <td>{{ r.description }}</td>
          <td>
            <a
              @click="reject($event, r.id)"
              class="has-text-danger"
            >
              reject
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";

export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
  },
  methods: {
    reject(e, id) {
      if (confirm("Are you sure you want to reject this deal claim? This may result in a poor customer experience.")) {
         this.send_status(e, id, "rejected")
      } else {
        console.log("cancelled")
      }
    },
    approve(e, id) {
      if (confirm("Are you sure you want to approve this deal claim? This cannot be undone.")) {
        this.send_status(e, id, "approved")
      }
    },
    send_status(e, id, status) {
      e.target.setAttribute('class', 'button is-small is-primary is-loading')
      const params = {
        user_slug: this.currentUser.slug,
        org_slug: this.currentBusiness.slug,
        id: id,
        status: status
      }
      this.$store.dispatch("updateBusinessTransactionStatus", params)
        .then(resp => {
          this.transactions = resp
        })
        .catch(err => {
          if (err instanceof AuthError || err instanceof NetworkError) {
            throw err
          } else {
            this.error = err 
          }
        })
        .finally(() => {
          e.target.parentNode.parentNode.setAttribute('class', 'is-hidden')
        })
    }
  }
};
</script>

<style>
.table-container p {
  margin: 1rem;
}
</style>
